import { Link } from "@remix-run/react";
import { type RemixLinkProps } from "@remix-run/react/dist/components.js";
import { cva, cx, type VariantProps } from "cva";
import { forwardRef, useCallback } from "react";

import { ButtonContent } from "#src/components/Buttons/ButtonContent.tsx";
import {
  type CommonButtonProps,
  commonButtonClassName,
} from "#src/components/Buttons/common.ts";

const buttonClassName = cva("", {
  defaultVariants: {
    size: "md",
    width: "default",
    wrapping: "nowrap",
  },
  variants: {
    size: {
      lg: "h-12 px-12 text-lg font-medium",
      md: "h-10 px-10 font-medium",
      sm: "h-8 px-8 text-sm font-medium",
      xs: "h-8 px-4 text-sm font-medium",
    },
    width: {
      default: "",
      full: "w-full",
      responsive: "max-sm:w-full",
    },
    wrapping: {
      nowrap: "whitespace-nowrap",
      wrap: "whitespace-wrap",
    },
  },
});

const buttonIconClassName = cva("absolute transition-all", {
  compoundVariants: [
    {
      className: "-left-7",
      position: "left",
      size: "lg",
    },
    {
      className: "-left-6",
      position: "left",
      size: "md",
    },
    {
      className: "-left-5",
      position: "left",
      size: "sm",
    },
    {
      className: "-right-7",
      position: "right",
      size: "lg",
    },
    {
      className: "-right-6",
      position: "right",
      size: "md",
    },
    {
      className: "-right-5",
      position: "right",
      size: "sm",
    },
  ],
  defaultVariants: {
    position: "left",
    size: "md",
  },
  variants: {
    position: {
      // intentionally empty for now, these props drive the compoundVariants
      left: "",
      right: "",
    },
    size: {
      lg: "h-5 w-5",
      md: "h-4 w-4",
      sm: "h-3 w-3",
      xs: "h-3 w-3",
    },
  },
});

export type ButtonProps = Omit<JSX.IntrinsicElements["button"], "ref"> &
  CommonButtonProps &
  VariantProps<typeof buttonClassName> & {
    iconPosition?: VariantProps<typeof buttonIconClassName>["position"];
  };

/**
 * Button
 *
 * Renders our standard buttons with support for a loading indicator and/or an icon.
 * The icon and loading indicator will be rendered in the same location (either left or
 * right of the main content) and swapped smoothly during the loading transition.
 *
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  {
    children,
    className,
    disabled,
    icon,
    iconClassName,
    iconPosition,
    color,
    loading = false,
    treatment,
    width,
    size,
    type = "button",
    wrapping,
    onClick,
    ...props
  },
  ref
) {
  const onClickOverride: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    e => {
      if (loading) {
        e.preventDefault();
        e.stopPropagation();
        return;
      }

      onClick?.(e);
    },
    [loading, onClick]
  );

  return (
    <button
      aria-disabled={disabled || loading}
      className={cx(
        commonButtonClassName({
          treatment,
          color: disabled === true ? "disabled" : color,
        }),
        buttonClassName({
          size,
          width,
          wrapping,
        }),
        loading && "cursor-not-allowed",
        className
      )}
      disabled={disabled}
      onClick={onClickOverride}
      ref={ref}
      type={type}
      {...props}
    >
      <ButtonContent
        className="mx-auto"
        iconClassName={buttonIconClassName({
          size,
          className: iconClassName,
          position: iconPosition,
        })}
        icon={icon}
        loading={loading}
      >
        {children}
      </ButtonContent>
    </button>
  );
});

export type LinkButtonProps = ButtonProps & RemixLinkProps;

/**
 * LinkButton
 *
 * Renders a link styled as a button with the same icon and/or
 * loading indicator support as Button.
 *
 */
export const LinkButton = forwardRef<HTMLAnchorElement, LinkButtonProps>(
  function LinkButton(
    {
      children,
      className,
      icon,
      iconClassName,
      iconPosition,
      color,
      loading = false,
      treatment,
      wrapping,
      width,
      size,
      ...props
    },
    ref
  ) {
    return (
      <Link
        ref={ref}
        className={cx(
          commonButtonClassName({
            color,
            treatment,
          }),
          buttonClassName({
            size,
            width,
            wrapping,
          }),
          "cursor-pointer no-underline",
          className
        )}
        {...props}
      >
        <ButtonContent
          className="mx-auto"
          iconClassName={buttonIconClassName({
            size,
            className: iconClassName,
            position: iconPosition,
          })}
          icon={icon}
          loading={loading}
        >
          {children}
        </ButtonContent>
      </Link>
    );
  }
);
